<template>
  <Teleport to="body">
    <FadeInTransition>
      <div
        v-if="isOpen"
        class="fixed right-0 top-0 z-[100] flex w-full bg-black/50"
        :class="fullScreen ? 'h-full' : 'min-h-screen'"
      >
        <div
          ref="modalRef"
          class="relative m-auto size-full rounded-2xl bg-white p-10 md:w-[37.5rem]"
          :class="{ '!h-[95%] !w-[95%]': fullScreen }"
        >
          <slot name="headline" />
          <button
            v-if="!hideCloseButton"
            data-testid="close-button"
            class="absolute right-6 top-6 z-50 cursor-pointer p-3"
            :aria-label="$t('modal.close')"
            @click="close"
          >
            <LazyIconFielmannClose class="size-6" />
          </button>
          <slot />
        </div>
      </div>
    </FadeInTransition>
  </Teleport>
</template>

<script setup lang="ts">
type Props = {
  hideCloseButton?: boolean
  fullScreen?: boolean
  closeOnOutside?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  hideCloseButton: false,
  fullScreen: false,
  closeOnOutside: true,
})

const emit = defineEmits(['close'])

const modalRef = ref()

const { isOpen, toggle } = useModal()

const close = () => {
  emit('close')
  toggle(false)
}

onClickOutside(modalRef, () => props.closeOnOutside && close())

const handleKeyUpEvent = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
}

let cleanup: ReturnType<typeof useEventListener>

onMounted(() => {
  toggle(true)
  cleanup = useEventListener('keyup', handleKeyUpEvent)
})

onUnmounted(() => {
  toggle(false)
  if (cleanup) {
    cleanup()
  }
})

defineOptions({ name: 'AppModal' })
</script>
